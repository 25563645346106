<template>
  <div class="resourceManage fx-col fx-1">
      <el-card>
        <div slot="header">
          <el-form
            class="form-item-no-mb"
            ref="searchForm"
            inline
            :model="searchForm"
            size="small"
          >
            <el-form-item label="计划名称">
              <el-input
                v-model="searchForm.employeeName"
                clearable
                placeholder="请输入计划名称"
              ></el-input>
            </el-form-item>
            <el-form-item label="计划名称">
              <el-input
                v-model="searchForm.studyPlanName"
                clearable
                placeholder="请输入计划名称"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-button @click="resetting">重置</el-button>
              <el-button type="primary" style="margin-left:16px;" @click="queryData(1)">查询</el-button>
            </el-form-item>
          </el-form>
        </div>
        <div>
          <el-button type="primary" @click="exportStudyInfo">导出</el-button>
        </div>
      </el-card>
      <div class="fx-row mt16 bac-fff fx-1">
        <!-- 列表 -->
        <div class="w200 fx-1">
          <el-table
            class="index-list-table"
            :data="tableData"
            height="calc(100vh - 350px)"
          >
            <el-table-column prop="employeeName" label="姓名">
              <template slot-scope="{ row }">{{row.employeeName||'—'}}</template>
            </el-table-column>
            <el-table-column prop="planName" label="关联计划名称">
              <template slot-scope="{ row }">{{row.planName||'—'}}</template>
            </el-table-column>
            <el-table-column prop="courseCode" label="课程编号">
              <template slot-scope="{ row }">{{row.courseCode||'—'}}</template>
            </el-table-column>
            <el-table-column prop="courseName" label="关联课程名称">
              <template slot-scope="{ row }">{{row.courseName||'—'}}</template>
            </el-table-column>
            <el-table-column prop="courseTypeValue" label="课程形式">
              <template slot-scope="{ row }">{{row.courseTypeValue||'—'}}</template>
            </el-table-column>
            <el-table-column prop="instructorName" label="带教人员">
              <template slot-scope="{ row }">{{row.instructorName||'—'}}</template>
            </el-table-column>
            <el-table-column prop="studyTypeValue" label="学习状态">
              <template slot-scope="{ row }">
                <div class="symbol">
                  <span
                    :class="{
                      grey: row.studyTypeValue === '未开始',
                      orange: row.studyTypeValue === '学习中',
                      green: row.studyTypeValue === '已完成',
                    }"
                    ></span
                  >
                  {{row.studyTypeValue||'—'}}
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="startTime" label="学习开始时间">
              <template slot-scope="{ row }">{{row.startTime||'—'}}</template>
            </el-table-column>
            <el-table-column prop="studySchedule" label="学习进度" width="180">
              <template slot-scope="{ row }">
                <el-progress
                  :text-inside="true"
                  :stroke-width="26"
                  :percentage="row.studySchedule||0"
                  text-color="#FFFFFF"
                ></el-progress>
              </template>
            </el-table-column>

            <el-table-column label="操作" width="144px" fixed="right">
              <template slot-scope="scope">
                <el-button
                  type="text"
                  class="text-btn mr24"
                  @click="studyDetail(scope.row)"
                  >学习明细</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            class="fx-row-end pt16"
            @size-change="_handleSizeChange"
            @current-change="_handleCurrentChange"
            :current-page="searchForm.page"
            :page-sizes="[10, 20, 30, 40, 50]"
            :page-size="searchForm.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="searchForm.tableTotal"
          >
          </el-pagination>
        </div>
      </div>
      <StudyDetail
      v-if="studyDetailShow"
      :studyDetailShow="studyDetailShow"
      :detailList="detailList"
      :currentItem="currentItem"
      />
  </div>
</template>
<script>
import StudyDetail from '../components/studyDetail'
import {
  getStudyProsessListApi,
  exportStudentProsessApi,
  studentProsessDetailApi
} from '@/api/study'
import { exportBtn } from '@/utils/util'
export default {
  components: {
    StudyDetail
  },
  data () {
    return {
      tableData: [],
      searchForm: {
        employeeName: '', // 姓名
        studyPlanName: '', // 计划名称
        planId: '',
        courseId: '',
        planIds: [],
        page: 1,
        pageSize: 10,
        tableTotal: 1
      },
      studyDetailShow: false, // 学习进度
      detailList: [],
      currentItem: {}
    }
  },
  created () {
    if (this.$route.query.studyPlanName) {
      this.searchForm.studyPlanName = this.$route.query.studyPlanName
    }
    this.queryData()
  },
  methods: {
    resetting () {
      this.searchForm = {
        employeeName: '', // 文件名称
        studyPlanName: '', // 计划名称
        page: 1,
        pageSize: 10
      }
      this.queryData()
    },
    queryData (type) {
      // 查询
      if (type) {
        this.searchForm.page = 1
      }
      getStudyProsessListApi(this.searchForm).then(res => {
        if (res.code === 200) {
          this.tableData = res.object.results
          this.searchForm.tableTotal = res.object.total
        }
      })
    },
    studyDetail (item) {
      // 学习明细
      studentProsessDetailApi(item).then(res => {
        if (res.code === 200) {
          this.detailList = res.data
          this.currentItem = item
          this.studyDetailShow = true
        }
      })
    },
    exportStudyInfo () {
      // 导出

      exportBtn(exportStudentProsessApi, { ...this.searchForm, page: undefined, pageSize: undefined }, '学习进度导出')
    },
    _handleSizeChange (val) {
      this.searchForm.pageSize = val
      this.queryData()
    },
    _handleCurrentChange (val) {
      this.searchForm.page = val
      this.queryData()
    }
  }
}
</script>
<style lang="scss" scoped>
.form-item-no-mb {
  /deep/.el-form-item {
    margin-bottom: 0;
  }
}
/deep/.index-list-table {
  thead {
    background-color: #ffffff !important;
  }
  tr {
    height: 51px;
    height: 54px;
    color: rgba(0, 0, 0, 0.9);
    font-size: 14px;
  }
  th {
    background: #f6f7f9 !important;
  }
}
/deep/.el-form-item {
  margin-bottom: 16px !important;
  margin-right: 32px !important;
}
/deep/.el-form-item__label {
  color: #000000;
}
/deep/ .el-button {
  font-size: 14px;
  height: 32px;
}
/deep/.el-button--text {
  margin: 0 10px 0px 0!important;
}
.symbol {
  display: flex;
  align-items: center;
  span {
    display: block;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    margin-right: 4px;
  }
}
.grey{
  background-color: #75849B;
}
.green {
  background-color: #10c038;
}
.orange {
  background-color: #ff931d;
}
</style>
