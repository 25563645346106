<!--
 * @Descripttion:
 * @version:
 * @Author: wenwen
 * @Date: 2023-11-20 10:16:29
 * @LastEditors: wenwen
 * @LastEditTime: 2024-03-14 11:53:12
-->
<template>
  <el-dialog
    :visible.sync="studyDetailShow"
    :before-close="cancel"
    :close-on-click-modal="false"
    title="学习进度"
    width="800px"
  >
    <el-form
      :model="dataForm"
      :rules="dataRule"
      ref="dataForm"
      label-width="50px"
      @keyup.enter.native="submitHandle()"
    >
      <el-row>
        <el-col :span="8" >
          <el-form-item label="员工">
            {{currentItem.employeeName}}
          </el-form-item>
        </el-col>
        <el-col :span="12" >
          <el-form-item label="进度">
            <el-progress
              :text-inside="true"
              :stroke-width="26"
              :percentage="currentItem.studySchedule||0"
              text-color="#FFFFFF"
            ></el-progress>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <el-table
        class="index-list-table"
        :data="detailList"
        height="calc(100vh - 650px)"
      >
        <el-table-column prop="fileName" label="课程小节">
          <template slot-scope="{ row }">{{row.fileName||'—'}}</template>
        </el-table-column>
        <el-table-column prop="startTime" label="开始学习时间">
          <template slot-scope="{ row }">{{row.startTime||'—'}}</template>
        </el-table-column>
        <el-table-column prop="endTime" label="结束学习时间">
          <template slot-scope="{ row }">{{row.endTime||'—'}}</template>
        </el-table-column>
        <el-table-column prop="learningDuration" label="有效学习时长（min）">
          <template slot-scope="{ row }">{{row.learningDuration?formatSeconds(Number(row.learningDuration)):'—'}}</template>
        </el-table-column>
      </el-table>
      <div slot="footer">
        <el-button @click="cancel">取消</el-button>
        <el-button type="primary" @click="submitHandle()">确认</el-button>
      </div>
  </el-dialog>
</template>
<script>
import fileType from '@/mixins/fileType'
export default {
  mixins: [fileType],
  props: {
    studyDetailShow: {
      type: Boolean,
      default: false
    },
    detailList: {
      type: Array,
      default: () => []
    },
    currentItem: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      dataForm: {
        employee: '',
        progress: 70
      },
      dataRule: {

      },
      tableData: [
        { name: '111' }
      ]
    }
  },
  methods: {
    cancel () {
      // 取消
      this.$parent.studyDetailShow = false
    },
    submitHandle () {
      this.cancel()
    }
  }
}
</script>
<style lang="scss" scoped>
   /deep/ .el-form-item__label{
        color: #999999 !important;
    }
    /deep/ .el-form-item__content{
        color: #000000 !important;
    }
</style>
